import {getLocalStorage, removeLocalStorage, setLocalStorage} from '@/utils/localStorage';
import router from '@/router';

const getPageIdentifier = () => {
  return `pageData:${router.currentRoute.fullPath}`
}

export const savePageData = (data: any) => {
  setLocalStorage(getPageIdentifier(), data)
}

export const getSavedPageData = () => {
  return getLocalStorage(getPageIdentifier())
}

export const removeStoredData = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('pageData:')) {
      removeLocalStorage(key)
    }
  })
}
