<template>
  <v-row class="auth__wrapper">
    <v-col
        class="auth__left-panel hidden-sm-and-down"
        md="6"
    />
    <v-col class="d-flex justify-center align-center auth__right-panel">
      <LanguageSelect
          class="language-select"
      />
      <div class="auth__container text-center">
        <h1>{{ $t('SYSTEM_SELLCON') }}</h1>
        <router-view />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';
import LanguageSelect from '@/components/LanguageSelect.vue';
import {removeStoredData} from "../utils/pageDataSaver";

@Component({
  name: 'AuthLayout',
  components: {LanguageSelect},
})
export default class AuthLayout extends Vue {
  mounted() {
    removeStoredData()
  }
}
</script>

<style
    lang="scss"
    scoped
>
.auth {
  &__container {
    width: 450px;
    @media only screen and (max-width: 475px) {
      width: auto
    }
  }
  &__wrapper {
    min-height: 100vh;
  }

  &__left-panel {
    height: 100vh;
    min-height: 100%;
    background-image: url('../assets/auth-img.jpg');
    background-size: cover;
  }

  &__right-panel {
    /*max-height: 100vh;*/
    /*overflow: auto;*/
    .language-select {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
